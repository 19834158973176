<template>
  <div class="about">
    <ul>
      <li>mɐcɹosɔian drøguluṡ</li>
    </ul>
  </div>
</template>

<style>
#miniature-text{
  color: rgb(223, 210, 193);
}
</style>
